import React from "react"
import Layout from "../../../../Components/pageComponents/contentContainer"
// import SolutionInfoSection from "../../../../Components/pageComponents/solutionsInfoSection"
import SolutionInfoSection from "../../../../Components/pageComponents/solutionsInfoSection"


export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/networking/wireless-networks/Wireless Access Points & Controllers.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stock: file(relativePath: { eq: "products/networking/wireless-networks/Wireless Network Access Point Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Access Points & Controllers" image={data.banner.childImageSharp.fluid}>
            <SolutionInfoSection heading="Products We Offer" title="Access Points and Controllers" description="Access Points are the portals that enable the devices to connect to the Local
                            Area Network (LAN). APs are also responsible for the extension of Network
                            Coverage and increase in the number of users that can connect to a Network.
                            Controllers are used to manage the Access Points.
                            Through the help of Fastlink, we can ensure a seamless and hassle-free connectivity.
                            WAN Acceleration/ Optimization helps:" img={data.stock.childImageSharp.fluid}>
            </SolutionInfoSection>
            {/* <div>
                <h3 style={{ margin: 0, padding: 0 }}>
                    <div class="py-5">
                        <div
                            class="container-md shadow-lg px-5 py-5"
                            style={{
                                color: "#F3F3F3",
                                backgroundColor: "#143469EE"
                            }}
                        >
                            Access Points are the portals that enable the devices to connect to the Local
                            Area Network (LAN). APs are also responsible for the extension of Network
                            Coverage and increase in the number of users that can connect to a Network.
                            Controllers are used to manage the Access Points.
                            Through the help of Fastlink, we can ensure a seamless and hassle-free connectivity.
                        </div>
                    </div>
                </h3>
            </div> */}

        </Layout>
    )
}